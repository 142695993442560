.header {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--var-primary-color);
  color: var(--var-on-primary-color);
  width: 100%;
  font-weight: 600;
  height: var(--var-header-height);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .12);
  gap: 10px;
  user-select: none;

}

.header>.title {
  /* display: inline-block;
  width: 100%; */
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  overflow: hidden;

  font-size: 22px;
}

@media (max-width: 600px) {
  .header>.title {
    font-size: 18px;
  }
}

@media (max-width: 300px) {
  .header>.title {
    font-size: 15px;
  }
}

.navButton {
  display: flex;
  background-color: var(--var-secondary-color);
  padding: 5px;
  margin-left: 10px;
  border: solid 1px rgba(204, 204, 204, 0.412);
  border-radius: 15px;
  cursor: pointer;
  user-select: none;
  transition: transform 0.75s;
  color: var(--var-on-secondary-color);

}

.navButton.navIsOpen {
  transform: rotate(90deg);
}

.header .rightOptions {
  display: flex;
  align-items: center;
}