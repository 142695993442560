.content {
  flex-grow: 1;
  margin-top: 15px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, .30);
}

.contentOuter {
  /* margin-left: calc(var(--nav-size) + 10px); */
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: calc(100% - var(--nav-size));
  /* min-height: calc(100vh - var(--var-header-height) - var(--var-footer-height) - 15px); */
  height: calc(100vh - var(--var-header-height) - var(--var-footer-height) - 10px);
  height: calc(100dvh - var(--var-header-height) - var(--var-footer-height) - 10px);
  /* 10px = margin */
  /* margin-bottom: 10px; */
  margin-left: 5px;
  margin-right: 5px;
  padding: 0 15px;
  overflow-x: auto;
  transition: margin-left 0.5s;
  padding-bottom: 10px;
  flex-grow: 1;
}


/* 
.wrapper.fixed>*>.contentHeader {
  padding: 0px 0px; 
}
*/
.wrapper.fixed>.contentOuter {
  margin-left: 5px;
  margin-right: 5px;
}

/* .content.navIsOpen {
  margin-left: calc(var(--nav-size) + 20px);
} */

@media only screen and (max-width: 700px) {
  .content {
    padding: 5px;
  }

  .contentOuter {
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
}