
.LoggedInPageTitle{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 7px;
    margin-left: 5px;
}
.LoggedInPageDescription{
    font-size: 18px;
    margin-left: 5px;
    margin-bottom: 10px;
}