.contentHeader {
    display: flex;
    gap: 15px;
    margin-top: 7px;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, .30);
    user-select: none;
    font-size: 15px;
    font-weight: 500;
    align-items: center;
}

.contentHeader .contentHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-left: solid 1px rgba(0, 0, 0, .15);
  border-right: solid 1px rgba(0, 0, 0, .15); */
    flex-grow: 1;
    min-height: 40px;
}

.contentHeader .contentHead .contentTitle {
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
}

.contentHeader .contentHead .contentButtons {
    display: flex;
    gap: 10px;

    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
}

.contentHeader .divideline {
    margin-top: 5px;
    border-top: solid 1px rgba(0, 0, 0, .15);
}

.contentHeader .informationsFlex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px 20px;
    padding: 0px 5px;
    padding-top: 7px;
}

.contentHeader .informationsGrid {
    display: grid;
    grid-gap: 5px 20px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 300px));
    padding: 0px 5px;
    padding-top: 7px;
    box-sizing: border-box;
}

.tabContainer {
    display: flex;
    align-items: center;
    justify-content: stretch;
    /* width: 100%; */
    /* background-color: #97979769; */
    padding: 0 10px;
    box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, .30);
    background-color: #fff;
}

.tabContainer a:hover {
    text-decoration: none;
}

.tabItem {
    /* border-right: solid 1px black; */
    display: flex;
    /* height: 100%; */
    align-self: center;
    padding: 3px 15px;
    border-top: solid 3px rgba(255, 255, 255, 0);
    border-bottom: solid 3px rgba(255, 255, 255, 0);
    font-weight: 600;
    white-space: nowrap;
    transition: border-color 1s,
        color 0.5s;
    user-select: none;
}

.tabItem:hover {
    color: var(--var-secondary-color);
    border-top: solid 2px var(--var-secondary-color) !Important;
    border-bottom: solid 2px var(--var-secondary-color) !Important;
    /* border-top: solid 3px var(--var-secondary-color);
    border-bottom: solid 3px var(--var-secondary-color); */
    /* font-weight: 600; */
}

.active {
    font-weight: 600;
    color: var(--var-primary-color) !Important;
    border-top: solid 3px var(--var-primary-color) !Important;
    border-bottom: solid 3px var(--var-primary-color) !Important;
}

.tabItemMenu {
    color: var(--var-secondary-color) !Important;
    /* border-right: solid 1px black; */
    display: flex;
    font-weight: 600;
    white-space: nowrap;
    transition: border-color 1s,
        color 0.5s;
    text-decoration: none;
    width: 100%;
}

.tabItemMenu:hover {
    text-decoration: none;
}

.activeMenu {
    font-weight: 600;
    color: var(--var-primary-color) !Important;
    /* border-bottom: solid 3px var(--var-primary-color) !Important; */
}

.offline {
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, .30);
    user-select: 'none';
    padding: 3px;
    background-color: #ffe9e9;
}

.loading {
    box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, .30);
    user-select: 'none';
}