.rowElementColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    margin: 5px 0px;
}

.rowElementColumn .rowElementTitle {
    /* font-weight: 500; */
    /* font-size: 15px; */
    /* margin-bottom: 3px; */
}

.rowElementColumn .hint {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
}