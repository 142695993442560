.btn_profile {
  color: white;
  margin-right: 15px;
  border-radius: 19px;
  padding: 4px 5px;
  line-height: 19px;
  background-color: #ccc;
  cursor: pointer;
}

.btn_profile:hover {
  background-color: #333;
}