.navigation {
  position: sticky;
  top: 0px;
  z-index: 1;
  transition: width 0.5s;
  /* height: calc(100vh - var(--var-header-height) - var(--var-footer-height) - 10px); */
}

.navigation a {
  color: #000;
}

.navigation a:hover {
  text-decoration: none;
}

.navigation.navIsClosed {
  display: none;
}

.item {
  transition: all 0.5s;
  border: solid 1px black;
  margin: 4px;
  margin-bottom: 10px;
  padding: 3px 10px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .12);
  cursor: pointer;
  white-space: nowrap;
  padding-left: 20px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.itemBox:first-child .item {
  margin-top: 0px;
}

.active .item {
  color: var(--var-on-primary-color);

  background-color: var(--var-primary-color);
}


.item:hover {
  background-color: var(--var-secondary-color);
  color: var(--var-on-secondary-color);
}

.itemBox .childItem {
  transition: all 0.5s;
  margin-left: 10px;

  height: 0px;
  overflow: hidden;
  margin-bottom: -2px;

}

.itemBox .childItem {
  margin-top: -2px;
}

.childItem .item {
  padding-left: 10px;
}

.childItem .item:hover {
  background-color: var(--var-secondary-color) !important;
}

.active+.childItem {
  height: var(--childHeight) !important;
}