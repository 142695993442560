dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

@media (min-width:1536px) {
  dialog {
    min-width: 1000px;
    max-width: 1436px;
  }
}

.modal-header .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-header {
  min-height: 70px;
  max-height: 100px;
  background-color: var(--var-primary-color);
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 16px;
}

.modal-body {
  /* display: flex; */
  height: 100%;
  /* min-height: 300px; */
  /* min-height Extra für Apple :/ */
  justify-content: space-evenly;
  overflow-y: auto;
  padding: 10px 16px;
  /* padding-bottom: 15px; */
}

.modal-footer {
  color: white;
  background-color: var(--var-secondary-color);
  padding: 10px 10px;
}


.topRightButtons {
  float: right;
  margin-top: 7px;
}

.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.help {
  float: right;
  padding-right: 15px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}